/* Hide scrollbar for Chrome, Safari and Opera */
.side-nav-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.side-nav-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

* {
  scroll-behavior: smooth;
}

#mobile {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#mobile h1 {
  width: 100%;
  padding: 0px 15px;
  color: #130080;
  text-align: center;
  font-size: 1.2em;
}

#mobile img {
  width: 25%;
  margin-bottom: 1em;
}

@media (max-width: 1200px) {
  .component-container {
    width: 1150px;
    overflow-x: auto;
  }
  .side-nav-container {
    width: 240px !important;
  }
}
